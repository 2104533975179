<template>
  <!-- Modal Brochures -->
  <div :id="modalContent.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-brochures" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title brochures" id="Brochures">
            {{ modalContent.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <!--brochure-->
          <div class="brochure" v-for="pdf in modalContent.PDFs" :key="pdf.id">
            <div class="brochure-img">
              <img v-if="pdf.Preview_Image"
                   :src="getBaseUrl() + pdf.Preview_Image.url"
                   :width="pdf.Preview_Image.width" :height="pdf.Preview_Image.height"
                   class="img-fluid"
                   :alt="pdf.Preview_Image.alternativeText"
                   :title="pdf.Preview_Image.caption">
            </div>
            <div class="brochure-info">
              <h6>{{ pdf.Title }}</h6>
              <span>PDF {{ pdf.PDF.size }} KB</span>
              <div class="brochoure-actions">
                <a class="btn btn-modal" :style="ButtonColor" :href="getPdfUrl(pdf)" target=“_blank”>
                  {{ $t('brochures.button.open') }}
                </a>
                <!-- <a class="btn btn-modal outline" :style="ButtonColorOutline" :href="getPdfUrl(pdf)" :download="pdf.PDF.name">
                  {{ $t('brochures.button.download') }}
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {baseUrl, httpPostForFile} from "@/http-utils";

export default {
  name: 'ModalBrochures',

  props: {
    modalConfig: {type: Object, required: true},
    modalContent: {type: Object, required: true},
  },

  data() {
    return {
      ButtonColor: {
        backgroundColor: '#1e4295 !important',
        color: '#ffffff !important',
      },
      ButtonColorOutline: {
        borderColor: '#1e4295 !important',
        color: '#1e4295 !important'
      }
    }
  },

  methods: {
    downloadPdf(doc) {
      httpPostForFile(doc.PDF.url, {})
        .then((response) => {
          const blob = new Blob([response.data], {type: 'application/pdf'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = doc.PDF.name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });

    },

    openPdf(doc) {
      window.open((baseUrl + doc.PDF.url), "_blank");
    },

    getBaseUrl() {
      return baseUrl;
    },

    getPdfUrl(doc) {
      return (baseUrl + doc.PDF.url);
    }
  }
}
</script>


<style lang="scss">
button {
  position: relative;
}

.brochure {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 20px 0;
  border-bottom: 1px dashed var(--line-color);

  &:first-child {
    padding: 0 0 20px;
  }

  &:last-child {
    border-bottom: 0;
  }

  .brochure-img {
    flex: 0 0 140px;
    width: 140px;
    height: 180px;
    background-color: var(--img-bg-color);
    border: 1px solid var(--img-border-color);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include before-nav-icon($icon-file, 16px, 16px,);

    &::before {
      background-color: var(--grey-bg-color);
    }

    img {
      border-radius: 20px;
    }
  }

  .brochure-info {
    margin-left: 30px;

    h6 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      color: var(--grey-font-color);
    }

    .brochoure-actions {
      margin-top: 20px;

      button {
        margin-right: 5px;
      }

    }
  }
}
</style>
