<template>
  <div class="loading" :class="{ hidden: hideLoading }">
    <div class="overlay"></div>
    <div class="background" :style="{backgroundImage: 'url(' + backgroundImageUrl + ')'}"></div>

    <div
      :class="['panel',configData.Loading.Loading_Template.Style, configData.Loading.Loading_Template.Positions, configData.Loading.Loading_Template.Mode]">
      <div class="border-line"></div>
      <div class="main-panel">
        <div class="panel-logo">
          <img
            class="img-fluid"
            :src="companyLogo"
            srcset="../assets/companies/pharma-bavaria@2x.png 2x"
            width="250" height="150"
            alt="Pharma Bavaria International"
            title="Pharma Bavaria International"
          >
        </div>
        <div class="panel-content">
          <h1>{{ configData.Loading.Title }}</h1>
          <p>{{ configData.Loading.Summary }}</p>
          <div class="progress" :class="{ hidden: (loadingProcess==100) }">Loading {{ loadingProcess }}%</div>
          <button type="button" class="btn btn-enter"
                  :style="{backgroundColor: configData.Color_Schema.BackgroundColor_HEX, color: configData.Color_Schema.FontColor_HEX}"
                  v-if="(loadingProcess==100)"
                  @click="entryClicked()">{{ configData.Loading.Button }}
          </button>
        </div>
        <div class="panel-footer">
          <div class="panel-menu">
            <!--            <div class="panel-language">-->
            <!--              <ul>-->
            <!--                <li><a href="" class="active">Deutsch</a></li>-->
            <!--                <li><a href="">English</a></li>-->
            <!--                <li><a href="">French</a></li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <div class="panel-imprint" v-if="imprintConfig">
              <ul>
                <li><a href="" data-bs-toggle="modal" :data-bs-target="'#' + imprintConfig.Touchpoints_ID">{{ imprintConfig.Button }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="panel-made-by">
            <p v-html="settings.Made_by"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import httpGet, {baseUrl} from "@/http-utils";

export default {
  name: 'Loading',

  props: {
    configData: {type: Object, required: true},
    imprintConfig: {type: Object},
    loadingProcess: {type: Number, required: true},
  },

  computed: {
    companyLogo: function () {
      return baseUrl + this.configData.Logo.url
    },
    backgroundImageUrl: function () {
      console.log(baseUrl + this.configData.Loading.Background.url);
      return baseUrl + this.configData.Loading.Background.url
    }
  },

  data() {
    return {
      settings: {},
      hideLoading: false,
    }
  },

  methods: {
    entryClicked() {
      this.hideLoading = true;
    }
  },

  created() {
    httpGet("/settings")
      .then((data) => {
        this.settings = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

}
</script>


<style lang="scss">

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 990;
  background: var(--white);
  background-size: auto 100%;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--black);
    opacity: 0.3;
    z-index: 1;
  }

  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &.hidden {
    display: none !important;
  }

  .hidden {
    display: none !important;
  }

  .panel {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    overflow: hidden;

    &.floating {
      height: 100vh;
      width: 100%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      &.left {
        top: initial;
        bottom: initial;
        left: 0;
        right: initial;
        justify-content: flex-start;

        .main-panel {
          width: 520px;
          min-height: 710px;
          border-radius: 20px;
          top: initial;
          bottom: initial;
          left: 0;
          right: initial;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          animation: 2.0s ease-out 0s 1 LoadingSlideInFromLeft;
          transition: 0.5s;
          margin-left: 80px;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: 0;
          }
        }
      }

      &.right {
        top: initial;
        bottom: initial;
        left: initial;
        right: 0;
        justify-content: flex-end;

        .main-panel {
          width: 520px;
          min-height: 710px;
          border-radius: 20px;
          top: initial;
          bottom: initial;
          left: initial;
          right: 0;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          animation: 1.0s ease-out 0s 1 LoadingSlideInFromRight;
          transition: 0.5s;
          margin-right: 80px;
          @include media-breakpoint-down(md) {
            width: 100%;
            margin-right: 0;
          }
        }
      }


    }

    &.fixed {
      height: 100vh;
      width: 100%;
      @include media-breakpoint-down(md) {
        width: 100%;
      }

      .border-line {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 30px solid var(--primary-bg-color);
        pointer-events: none;
      }

      &.left {
        top: initial;
        bottom: initial;
        left: 0;
        right: initial;
        justify-content: flex-start;

        .main-panel {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          width: 520px;
          min-height: 100vh;
          border-radius: 0;
          top: initial;
          bottom: initial;
          left: 0;
          right: initial;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          animation: 2.0s ease-out 0s 1 LoadingSlideInFromLeft;
          transition: 0.5s;
        }
      }

      &.right {
        top: initial;
        bottom: initial;
        left: initial;
        right: 0;
        justify-content: flex-end;

        .main-panel {
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          width: 520px;
          min-height: 100vh;
          border-radius: 0;
          top: initial;
          bottom: initial;
          left: initial;
          right: 0;
          text-align: left;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          animation: 1.0s ease-out 0s 1 LoadingSlideInFromRight;
          transition: 0.5s;
        }
      }
    }
  }

  .main-panel {
    background-color: var(--primary-bg-color);
    box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.2);
    width: 520px;
    min-height: 710px;
    border-radius: 20px;
    top: initial;
    bottom: initial;
    left: 0;
    right: initial;
    padding: 40px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    animation: 1.0s ease-out 0s 1 slideInFromLeft;
    transition: 0.5s;

    .panel-logo {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
    }

    .panel-content {
      width: 100%;
      position: relative;

      h1 {
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 10px;
        @include media-breakpoint-down(md) {
          font-size: 32px;
        }
      }

      p {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 40px;
        @include media-breakpoint-down(md) {
          font-size: 18px;
        }
      }

      .progress {
        background-color: var(--button-bg-loading);
        color: var(--primary-font-color);
        width: 100%;
        height: 70px;
        font-size: 24px;
        text-align: center;
        padding: 0.375rem 0.75rem;
        border-radius: 20px;
        font-weight: 600;
        text-transform: uppercase;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include media-breakpoint-down(md) {
          font-size: 20px;
        }
      }

      .btn-enter {
        background-color: var(--button-bg-default);
        color: var(--secondary-font-color) !important;
        width: 100%;
        height: 70px;
        border-radius: 20px;
        font-size: 24px;
        font-weight: 600;
        position: relative;
        @include media-breakpoint-down(md) {
          font-size: 20px;
        }

        &:hover {
          opacity: .75;
        }
      }
    }

    .panel-footer {
      margin-top: 100px;
      width: 100%;

      .panel-menu {
        display: flex;
        justify-content: space-between;
      }

      .panel-language,
      .panel-imprint {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: flex;

          li {
            margin-right: 10px;

          }
        }

        a {
          color: var(--primary-font-color);
          text-decoration: none;
          font-weight: 300;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }

          &.active {
            font-weight: 600;
          }
        }

      }

      .panel-imprint {
        a {
          color: var(--grey-font-color);
          text-decoration: none;
          font-weight: 300;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
        }
      }

      .panel-made-by {
        color: var(--grey-font-color);
        font-size: 14px;
        border-top: 1px dashed var(--line-color);
        padding-top: 10px;
        margin-top: 10px;
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }

    }
  }

}


</style>
