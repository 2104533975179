<template>
  <!-- Modal Video -->
  <div :id="modalContent.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-video" aria-hidden="true">
    <div class="modal-dialog video">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title video" id="Video">
            {{ modalContent.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="videoframe" v-if="renderVideo">
            <iframe class="video"
                    :src="modalContent.Link"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalVideo',

  props: {
    modalConfig: {type: Object, required: true},
    modalContent: {type: Object, required: true},
  },

  data() {
    return {
      renderVideo: false,
    }
  },

  methods: {
    closeModal() {
      this.renderVideo = false;
    },
  },

  mounted() {
    var self = this;
    const myModal = document.getElementById(this.modalContent.Touchpoints_ID);
    myModal.addEventListener('shown.bs.modal', function () {
      self.renderVideo = true;
    });
    myModal.addEventListener('hidden.bs.modal', function () {
      self.renderVideo = false;
    })
  }
}
</script>


<style lang="scss">
button {
  position: relative;
}

.modal {
  &.video {
    .modal-dialog {
      max-width: 90%;
      margin: 1.75rem auto;
    }
  }
}

.videoframe {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 46.25%;

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
