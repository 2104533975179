<template>
  <div id="container">
  </div>
</template>

<script>
import * as THREE from 'three/build/three.module'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import CameraControls from '@/components/Configuration/cameraMovment.js';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader.js';
import TWEEN from '@tweenjs/tween.js';
import Navigation from '@/components/Navigation.vue';
import {baseUrl} from "@/http-utils";
import {Modal} from "bootstrap";


export default {
  name: 'ThreeJS',

  props: {
    floorImageConfig: {type: Object, required: true},
    panoramaImageConfig: {type: Object, required: true},
    cameraAndLightConfig: {type: Object, required: true},
    glbConfig: {type: Object, required: true},
    meetingGlbConfig: {type: Object, required: true},
    touchpointsData: {type: Array, required: true},
  },

  emits: ["increase-loading"],

  data() {
    return {
      todosList: [],
    };
  },

  components: {
    Navigation
  },

  mounted() {

    CameraControls.install({THREE: THREE});

    var container = document.getElementById('container');
    container.innerHTML = "";

    let sceneMeshes = [];
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
   
    const width = window.innerWidth;
    const height = window.innerHeight;
    const clock = new THREE.Clock();
    const scene = new THREE.Scene();

    const settings = this.cameraAndLightConfig.settings;
    // console.log(JSON.parse(JSON.stringify(settings)));


    var composer, outlinePass, helper;

    const camera = new THREE.PerspectiveCamera(50, width / height, 0.01, 1000);
    camera.position.set(0, 9, 15);

  const curve = new THREE.CatmullRomCurve3();
    this.cameraAndLightConfig.cameraStartWayAnchors.forEach(item => curve.points.push(new THREE.Vector3(item.xyz[0], item.xyz[1], item.xyz[2])))


    const renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.toneMapping = THREE.CineonToneMapping;
    renderer.toneMappingExposure = settings.toneMappingBrightness;

    container.appendChild(renderer.domElement);

    const cameraControls = new CameraControls(camera, renderer.domElement);
    cameraControls.minDistance = settings.cameraZoomMin;
    cameraControls.maxDistance = settings.cameraZoomMax;
    cameraControls.maxPolarAngle = Math.PI / 2;

    cameraControls.addEventListener('controlstart', function () {
      switch (cameraControls.currentAction) {
        case CameraControls.ACTION.ROTATE:
        case CameraControls.ACTION.TOUCH_ROTATE: {
          renderer.domElement.classList.add('-dragging');
          break;
        }
        case CameraControls.ACTION.TRUCK:
        case CameraControls.ACTION.TOUCH_TRUCK: {
          renderer.domElement.classList.add('-moving');
          break;
        }
        case CameraControls.ACTION.DOLLY:
        case CameraControls.ACTION.ZOOM: {
          renderer.domElement.classList.add('-zoomIn');
          break;
        }
        case CameraControls.ACTION.TOUCH_DOLLY_TRUCK:
        case CameraControls.ACTION.TOUCH_ZOOM_TRUCK: {
          renderer.domElement.classList.add('-moving');
          break;
        }
        default: {
          break;
        }
      }
    });

    cameraControls.addEventListener('controlend', function () {
      renderer.domElement.classList.remove(
        '-dragging',
        '-moving',
        '-zoomIn'
      );
    });

    var textureLoader = new THREE.TextureLoader();
    var decalDiffuse = textureLoader.load('./model/cursor.png');
    var decalMaterial = new THREE.MeshPhongMaterial({
      map: decalDiffuse,
      transparent: true
    });

    const geometryHelper = new THREE.PlaneGeometry(0.05, 0.05, 1, 1);

    helper = new THREE.Mesh(geometryHelper, decalMaterial);
    helper.scale.set(settings.cursorScale, settings.cursorScale, settings.cursorScale);
    helper.rotateX(-Math.PI / 2);
    scene.add(helper);

    const planeGeometry = new THREE.PlaneGeometry(50, 50, 2, 2);

    const texturet = new THREE.TextureLoader().load((baseUrl + this.floorImageConfig.url));
    texturet.wrapS = texturet.wrapT = THREE.RepeatWrapping;
    texturet.repeat.set(25, 25);
    texturet.anisotropy = 16;
    texturet.encoding = THREE.sRGBEncoding;
    const meshz = new THREE.Mesh(planeGeometry, new THREE.MeshPhongMaterial({
      map: texturet,
      side: THREE.DoubleSide,
      color: 0xc2b7b7
    }));
    meshz.rotateX(-Math.PI / 2);
    meshz.receiveShadow = true;
    meshz.name = 'floorTexture';
    scene.add(meshz);


    renderer.render(scene, camera);

    let topLight = this.cameraAndLightConfig.topLight;
    const dLightTop = new THREE.DirectionalLight(topLight.color, topLight.brightness);
    dLightTop.position.set(+topLight.xyz[0], +topLight.xyz[1], +topLight.xyz[2]);
    dLightTop.shadow.bias = topLight.shadowBias;
    dLightTop.shadow.normalBias = topLight.shadowNormalBias;
    dLightTop.castShadow = topLight.castShadow;
    dLightTop.shadow.mapSize.width = topLight.shadowMapSizeWidth;
    dLightTop.shadow.mapSize.height = topLight.shadowMapSizeHeight;
    scene.add(dLightTop);


    this.cameraAndLightConfig.lights.forEach(dLight => {
      let light = {};
      if (dLight.type === 'DirectionalLight') {
        light = new THREE.DirectionalLight(dLight.color, dLight.brightness);
      } else if (dLight.type === 'SpotLight') {
        light = new THREE.SpotLight(dLight.color, dLight.brightness);
      } else {
        light = new THREE.PointLight(dLight.color, dLight.brightness);
      }

      light.position.set(dLight.xyz[0], dLight.xyz[1], dLight.xyz[2]);
      scene.add(light);
    });

    new RGBELoader()
      .setDataType(THREE.UnsignedByteType)
      // .setPath('./model/')
      .load((baseUrl + this.panoramaImageConfig.url), function (texture) {
        var envMap = pmremGenerator.fromEquirectangular(texture).texture;
        scene.background = envMap;
        scene.environment = envMap;
        texture.dispose();
        pmremGenerator.dispose();
      });

    const pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    const loader = new GLTFLoader();
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/');//copypasted draco/gltf/all files in public folder
    loader.setDRACOLoader(dracoLoader);
 if (window.location.href.indexOf('meeting=') !== -1) {
      loader.load((baseUrl + this.meetingGlbConfig.url), function (gltf) {

        scene.add(gltf.scene);

        gltf.scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {

            sceneMeshes.push(child);
          }
        });
      });
    }
    loader.load((baseUrl + this.glbConfig.url), function (gltf) {

        scene.add(gltf.scene);

        gltf.scene.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            if (child.name.indexOf('model-woman') !== -1) {
              child.material.roughness = settings.roughnessCharacter;
              child.material.metalness = settings.metalnessCharcater;
            } else {
              child.material.roughness = settings.roughness;
              child.material.metalness = settings.metalness;
            }

            child.receiveShadow = true;
            child.castShadow = true;
            sceneMeshes.push(child);
          }
        });
        
      }, (xhr) => {
     
        this.$emit("increase-loading", Math.ceil((xhr.loaded / xhr.total) * 100));
      	
	 		 loads(); 
      
       
      }, (error) => {
        console.log('hata');
      }
    );


var container = document.querySelector('#container');
    document.addEventListener('mousemove', onMouseMove, false);
    document.addEventListener('click', onDocumentClick, false);
    container.addEventListener('click', onMouseClick, false);

    function onMouseMove(event) {
      mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(sceneMeshes, false);
      if (intersects.length > 0) {


        if (intersects[0].object.name.indexOf('touchpointmove') !== -1) {

          helper.position.set(intersects[0].point.x, intersects[0].point.y + 0.07, intersects[0].point.z);

        } else {
          helper.position.set(0, 0, 0);
       
        }


      }
    }

    function onDocumentClick(event) {
      mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(sceneMeshes, false);

      if (intersects.length > 0) {
        const p = intersects[0].point;
        var x = p.x;
        //var y = p.y;
        var z = p.z;
      
        if (intersects[0].object.name.indexOf('touchpointmove') !== -1) {
          
          cameraControls.moveTo(x, settings.insideHeightGround, z, true)
        }
        
      }
    }

   

    for (let i = 0; i < this.touchpointsData.length; i++) {

      const btnt = document.createElement('button');
      btnt.setAttribute("type", "button");
      btnt.id = i;

      btnt.className = 'btn btn-nav btn-position ' + this.touchpointsData[i].contentType + ' ' + this.touchpointsData[i].id;
      btnt.innerHTML = '<span class="btn-text">' + this.touchpointsData[i].buttonName + '</span>';

      if (this.touchpointsData[i].isInMeeting) {
        const solnav = document.getElementById('touckpoints-buttons-meeting');
        if (solnav) solnav.appendChild(btnt);
      } else {
        const solnav = document.getElementById('touckpoints-buttons');
        solnav.appendChild(btnt);
      }


    }


	window.addEventListener( 'resize', onResize, false );

			function onResize() {

				renderer.setSize( window.innerWidth, window.innerHeight );
				camera.aspect = ( window.innerWidth / window.innerHeight );
				camera.updateProjectionMatrix();

			}






    let self = this;
function modal(id){
  const myModal = new Modal(document.getElementById(id));
        myModal.show();
}

    var solep = document.getElementsByClassName('btn-position');
    self = this;
   
    var solpp = function () {
      var isol = this.id.replace('b', '');
      var pointx = self.touchpointsData[isol].cameraView.x;
      var pointy = self.touchpointsData[isol].cameraView.y;
      var pointz = self.touchpointsData[isol].cameraView.z;
      var x = self.touchpointsData[isol].cameraPosition.x;
      var y = self.touchpointsData[isol].cameraPosition.y;
      var z = self.touchpointsData[isol].cameraPosition.z;

      if (self.touchpointsData[isol].justModal == 1) {
        modal(self.touchpointsData[isol].id);
      } else {
        if (camera.position.x < settings.planeBorderLeft || camera.position.x > settings.planeBorderRight || camera.position.z > settings.planeBorderFront || camera.position.z < settings.planeBorderBack) {
          let cameraInside = self.cameraAndLightConfig.cameraInsideWayAnchors.anchor1;
          const curveInside = new THREE.CatmullRomCurve3([
            new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z),
            new THREE.Vector3(cameraInside.xyz[0], cameraInside.xyz[1], cameraInside.xyz[2]),
            new THREE.Vector3(x, y, z),
          ]);

          const _tmp = new THREE.Vector3();
          const pathAnimation = new TWEEN.Tween({progress: 0}).to({progress: 1}, 3000).easing(TWEEN.Easing.Quadratic.Out).onStart(function () {
            cameraControls.enabled = false;
          })
            .onUpdate(function (values) {
              curveInside.getPoint(values.progress, _tmp);
              const cameraX = _tmp.x;
              const cameraY = _tmp.y;
              const cameraZ = _tmp.z;
              const lookAtX = pointx;
              const lookAtY = pointy;
              const lookAtZ = pointz;
              cameraControls.setLookAt(cameraX, cameraY, cameraZ, lookAtX, lookAtY, lookAtZ, false);
            })
            .onComplete(function () {
              cameraControls.enabled = true;

            });
          pathAnimation.start();
         

        } else {
    
            //modal(self.touchpointsData[isol].id);
            
         cameraControls.setLookAt(x, y, z, pointx, pointy, pointz, true);
        

        }
      }
    }

    for (var i = 0; i < solep.length; i++) {
      solep[i].addEventListener('click', solpp, false);
    }


 function onMouseClick(event) {

      mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      var intersects = raycaster.intersectObjects(sceneMeshes, false);
      var id='';
      if (intersects.length > 0) {

        const p = intersects[0].point;
       
        console.log(p);

        console.log(intersects[0].object.name);

        if (intersects[0].object.name.split('-')[0] == 'touchpoint') {
  
    id = intersects[0].object.name.split('_');
   var isol = document.getElementsByClassName(id[0] + '_' + id[1])[0].id;

      var pointx = self.touchpointsData[isol].cameraView.x;
      var pointy = self.touchpointsData[isol].cameraView.y;
      var pointz = self.touchpointsData[isol].cameraView.z;
      var x = self.touchpointsData[isol].cameraPosition.x;
      var y = self.touchpointsData[isol].cameraPosition.y;
      var z = self.touchpointsData[isol].cameraPosition.z;

         cameraControls.setLookAt(x, y, z, pointx, pointy, pointz, true);
        
          modal(id[0] + '_' + id[1]);
         
        } else if (intersects[0].object.name.split('-')[0] == 'touchpointgomeeting') {

          id = intersects[0].object.name.replace('touchpointgomeeting-join', 'touchpoint-meeting').split('_');

      var isol = document.getElementsByClassName(id[0] + '_' + id[1])[0].id;

      var pointx = self.touchpointsData[isol].cameraView.x;
      var pointy = self.touchpointsData[isol].cameraView.y;
      var pointz = self.touchpointsData[isol].cameraView.z;
      var x = self.touchpointsData[isol].cameraPosition.x;
      var y = self.touchpointsData[isol].cameraPosition.y;
      var z = self.touchpointsData[isol].cameraPosition.z;

      cameraControls.setLookAt(x, y, z, pointx, pointy, pointz, true);
        

        } else if (intersects[0].object.name.split('-')[0] == 'touchpointgo') {

          id = intersects[0].object.name.split('_')[0];

          document.getElementsByClassName(id)[0].click();

        }

      }
    }

 


    let cameraOutside = self.cameraAndLightConfig.cameraStartWayAnchors[this.cameraAndLightConfig.cameraStartWayAnchors.length -1];
    let cameraInside = self.cameraAndLightConfig.cameraInsideWayAnchors.anchor1;
    document.querySelector('#goOutside').addEventListener('click', () => {
     
      const curveInside = new THREE.CatmullRomCurve3([
        new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z),
        new THREE.Vector3(cameraInside.xyz[0], cameraInside.xyz[1], cameraInside.xyz[2]),
        new THREE.Vector3(cameraOutside.xyz[0], cameraOutside.xyz[1], cameraOutside.xyz[2]),
      ]);

      const _tmp = new THREE.Vector3();
      const pathAnimation = new TWEEN.Tween({progress: 0})
        .to({progress: 1}, 3000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onStart(function () {
          cameraControls.enabled = false;
        })
        .onUpdate(function (values) {
          curveInside.getPoint(values.progress, _tmp);
          const cameraX = _tmp.x;
          const cameraY = _tmp.y;
          const cameraZ = _tmp.z;
          const lookAtX = 0;
          const lookAtY = settings.truckUpDown;
          const lookAtZ = 0;
          cameraControls.setLookAt(
            cameraX, cameraY, cameraZ, lookAtX, lookAtY, lookAtZ, false
          );
        })
        .onComplete(function () {
          cameraControls.enabled = true;
       
         
        });
      pathAnimation.start();

    });

    self = this;
    document.querySelector('#goInside').addEventListener('click', () => {
      let cameraInside = this.cameraAndLightConfig.cameraInsideWayAnchors.anchor1;

      const insideView = self.touchpointsData.filter(f => f.id === settings.insideViewPositionID)[0];
      const curveInside = new THREE.CatmullRomCurve3([
        new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z),
        new THREE.Vector3(cameraInside.xyz[0], cameraInside.xyz[1], cameraInside.xyz[2]),
        new THREE.Vector3(insideView.cameraPosition.x, insideView.cameraPosition.y, insideView.cameraPosition.z),
      ]);

      const _tmp = new THREE.Vector3();
      const pathAnimation = new TWEEN.Tween({progress: 0})
        .to({progress: 1}, 3000)
        .easing(TWEEN.Easing.Quadratic.Out)
        .onStart(function () {
          cameraControls.enabled = false;
        })
        .onUpdate(function (values) {
          curveInside.getPoint(values.progress, _tmp);
          const cameraX = _tmp.x;
          const cameraY = _tmp.y;
          const cameraZ = _tmp.z;
          const lookAtX = insideView.cameraView.x;
          const lookAtY = insideView.cameraView.y;
          const lookAtZ = insideView.cameraView.z;
          cameraControls.setLookAt(cameraX, cameraY, cameraZ, lookAtX, lookAtY, lookAtZ, false);
        })
        .onComplete(function () {
          cameraControls.enabled = true;
       
        });
      pathAnimation.start();

    });

function loads(){
    const _tmp = new THREE.Vector3();

    const pathAnimation = new TWEEN.Tween({progress: 0})
      .to({progress: 1}, 3000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onStart(function () {
        cameraControls.enabled = false;
      })
      .onUpdate(function (values) {
        curve.getPoint(values.progress, _tmp);
        const cameraX = _tmp.x;
        const cameraY = _tmp.y;
        const cameraZ = _tmp.z;
        const lookAtX = 0;
        const lookAtY = settings.truckUpDown;
        const lookAtZ = 0;
        cameraControls.setLookAt(cameraX, cameraY, cameraZ, lookAtX, lookAtY, lookAtZ, false);
      })
      .onComplete(function () {
        cameraControls.enabled = true;
        
      });
    pathAnimation.start();
}

    (function anim() {
      const delta = clock.getDelta();
      const elapsed = clock.getElapsedTime();
      const updated = cameraControls.update(delta);
      requestAnimationFrame(anim);
      TWEEN.update(elapsed * 1000);
      if (updated) {
        renderer.render(scene, camera);
      }
    })();

 

  }
}
</script>
<style>

canvas {
  cursor: grab;

}

canvas.-dragging {
  cursor: grabbing;
}

canvas.-moving {
  cursor: move;
}

canvas.-zoomIn {
  cursor: zoom-in;
}

#container {
  margin: 0px;
  overflow: hidden;

}
</style>
<style lang="scss">


.btn-touchpoints {
  position: absolute;
  display: flex;
  z-index: 99;
  top: 100px;
  left: 100px;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 1);
  background-color: rgba(39, 68, 116, 1);
  box-shadow: 0px 0px 0px 5px rgba(39, 68, 116, 0.45) !important;

  &.active {
    background-color: var(--button-bg-active);
  }

  &:hover {
    opacity: .75 !important;
  }

  &::before {
    background-color: var(--white) !important;
  }

  &.outside {
    @include before-nav-icon($icon-minus, 16px, 16px,);
  }

  &.inside {
    @include before-nav-icon($icon-plus, 16px, 16px);
  }

  &.fullscreen {
    @include before-nav-icon($icon-fullscreen, 14px, 14px);
  }

  &.content {
    @include before-nav-icon($icon-content, 14px, 14px);
  }

  &.video {
    @include before-nav-icon($icon-video, 18px, 12px);
  }

  &.brochures {
    @include before-nav-icon($icon-brochures, 18px, 14px);
  }

  &.request {
    @include before-nav-icon($icon-request, 20px, 16px);
  }

  &.contact {
    @include before-nav-icon($icon-contact, 16px, 12px);
  }

  &.imprint {
    @include before-nav-icon($icon-imprint, 16px, 14px);
  }
}
</style>
