<template>
  <!-- Modal Video -->
  <div :id="modalContent.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-meeting" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title meeting" id="Meeting">
            {{ meetingRoom.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-html="meetingRoom.Summary"></p>
          <button type="button" class="btn btn-modal btn-full" :style="ButtonColor" @click="joinMeeting()"> {{ meetingRoom.Button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalMeeting',

  props: {
    modalConfig: {type: Object, required: true},
    modalContent: {type: Object, required: true},
    meetingRoom: {type: Object, required: true},
  },

  data() {
    return {
      ButtonColor: {
        backgroundColor: '#1e4295 !important',
        color: '#ffffff !important',
      },
      ButtonColorOutline: {
        borderColor: '#1e4295 !important',
        color: '#1e4295 !important'
      }
    }
  },

  methods: {
    joinMeeting() {
      window.open(this.meetingRoom.Link, "_blank");
    }
  }
}
</script>


<style lang="scss">
button {
  position: relative;
}

.btn-modal {
  height: 45px;
  &.full{
    width: 100% !important;
  }
}
</style>
