<template>
  <!-- Modal Request -->
  <div :id="modalContent.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-request" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title request" id="request">
            {{ modalContent.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <p>{{ modalContent.Description }}</p>

          <!--request-us-->
          <div class="request-us" v-bind:class="{ hidden: selectedEmployee }">

            <!--  employee-->
            <div class="employee-box" v-for="employee in modalContent.Employee" :key="employee.id">
              <div class="employee">
                <div class="employee-info">
                  <img v-if="employee.Employee && employee.Employee.Photo[0]"
                       :src="(getBaseUrl() + employee.Employee.Photo[0].url)"
                       width="90" height="90"
                       class="img-fluid"
                       :alt="employee.Employee.Photo[0].alternativeText"
                       :title="employee.Employee.Photo[0].caption"
                  >
                  <img v-else
                       src="http://go.fxw.io/vivicon.online/downloads/default-image.jpg"
                       width="90" height="90"
                       class="img-fluid"
                  >
                  <div class="employee-name">
                    <span><strong>{{ employee.Employee.Full_Name }}</strong></span>
                    <span class="position">{{ employee.Employee.Position }}</span>
                  </div>
                </div>
                <div class="employee-action">
                  <button type="submit" class="btn btn-modal btn-md" :style="ButtonColor"
                          @click="selectEmployee(employee)">{{ $t('request.terminButton') }}
                  </button>
                </div>
              </div>
            </div>
            <!---->
          </div>
          <!--request-us-->

          <!--employe request-->
          <div class="employee-request" v-if="selectedEmployee">
            <!--  employee-->
            <div class="employee-box">
              <div class="employee">
                <div class="employee-info">
                  <img v-if="selectedEmployee.Employee && selectedEmployee.Employee.Photo[0]"
                       :src="(getBaseUrl() + selectedEmployee.Employee.Photo[0].url)"
                       width="90" height="90"
                       class="img-fluid"
                       :alt="selectedEmployee.Employee.Photo[0].alternativeText"
                       :title="selectedEmployee.Employee.Photo[0].caption"
                  >
                  <img v-else
                       src="http://go.fxw.io/vivicon.online/downloads/default-image.jpg"
                       width="90" height="90"
                       class="img-fluid"
                  >
                  <div class="employee-name">
                    <span><strong>{{ selectedEmployee.Employee.Full_Name }}</strong></span>
                    <span class="position">{{ selectedEmployee.Employee.Position }}</span>
                  </div>
                </div>
                <div class="employee-action">
                  <button type="submit" class="btn btn-modal outline btn-md" :style="ButtonColorOutline"
                          @click="selectEmployee(null)">{{ $t('request.backButton') }}
                  </button>
                </div>
              </div>
            </div>

            <div class="employee-form-request">
              <!-- alert-->
              <div class="alert alert-success" role="alert" v-if="showResultMessage">
                {{ $t('request.successMessage') }}
              </div>
              <form class="row" :class="{ hidden: showResultMessage }" @submit.prevent="sendRequest">
                <!--input box-->
                <div class="input-box">
                  <div class="step">
                    <div class="circle" :style="CircleColor">1</div>
                    <div class="line" :style="LineColor"></div>
                  </div>
                  <div class="input">
                    <p>{{ $t('request.form.selectDay') }}</p>
                    <input type="date" class="form-control" id="inputTerminDate"
                           :placeholder="$t('request.form.firstName')"
                           required
                           v-model="requestInfo.terminDate">
                  </div>
                </div>
                <!--input box-->
                <!--input box-->
                <div class="input-box">
                  <div class="step">
                    <div class="circle" :style="CircleColor">2</div>
                    <div class="line" :style="LineColor"></div>
                  </div>
                  <div class="input">
                    <p>{{ $t('request.form.selectTime') }}</p>

                    <div class="form-check" v-for="timeBlock in selectedEmployee.Time_Blocks" :key="timeBlock.id">
                      <input class="form-check-input" type="radio" name="timeBlocksRadio" :id="timeBlock.id"
                             :value="timeBlock.id" v-model="requestInfo.timeBlock">
                      <label class="form-check-label" :for="timeBlock.id">
                        {{ timeBlock.Name }} {{ $t('request.form.hour') }}
                      </label>
                    </div>

                  </div>
                </div>
                <!--input box-->
                <!--input box-->
                <div class="input-box">
                  <div class="step">
                    <div class="circle" :style="CircleColor">3</div>
                  </div>
                  <div class="input">
                    <p>{{ $t('request.form.contactInfo') }}</p>
                    <div class="row g-3">
                      <div class="col-12">
                        <input type="text" class="form-control" id="inputCompany"
                               :placeholder="$t('request.form.company')"
                               v-model="requestInfo.company">
                      </div>

                      <div class="col-6">
                        <input type="text" class="form-control" id="inputFirstName"
                               :placeholder="$t('request.form.firstName')"
                               v-model="requestInfo.firstName"
                               required
                               :class="{error: errMsg['firstName'] !== undefined}">
                      </div>

                      <div class="col-6">
                        <input type="text" class="form-control" id="inputLastName"
                               :placeholder="$t('request.form.lastName')"
                               v-model="requestInfo.lastName"
                               required
                               :class="{error: errMsg['lastName'] !== undefined}">
                      </div>


                      <div class="col-md-12">
                        <input type="email" class="form-control" id="inputEmail"
                               :placeholder="$t('request.form.email')"
                               v-model="requestInfo.email"
                               required
                               :class="{error: errMsg['email'] !== undefined}">
                      </div>

                      <div class="col-md-12">
                        <input type="phone" class="form-control" id="inputPhoneNumber"
                               :placeholder="$t('request.form.phoneNumber')"
                               v-model="requestInfo.phoneNumber"
                               required
                               :class="{error: errMsg['phoneNumber'] !== undefined}">
                      </div>


                      <div class="col-md-12">
                        <textarea class="form-control" id="textareaMessages" rows="3"
                                  :placeholder="$t('request.form.message')"
                                  v-model="requestInfo.message"></textarea>
                      </div>

                      <div class="col-12">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="gridCheck"
                                 v-model="requestInfo.dataUsage"
                                 required
                                 :class="{error: errMsg['dataUsage'] !== undefined}">
                          <label class="form-check-label checkbox" for="gridCheck">
                            {{ $t('request.form.dataUsage') }}
                          </label>
                        </div>
                      </div>
                      <div class="col-12 d-grid gap-2">
                        <button type="submit" class="btn btn-modal btn-md btn-full" :style="ButtonColor">
                          {{ $t('request.form.button') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!--input box-->
              </form>
            </div>
            <!---->
          </div>
          <!--employe request-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {baseUrl, httpPost} from "@/http-utils";

export default {
  name: 'ModalRequest',

  props: {
    companyId: {type: String, required: true},
    companyName: {type: String, required: true},
    modalConfig: {type: Object, required: true},
    modalContent: {type: Object, required: true},
  },

  computed: {
    employeeImageUrl: function () {
      return (this.modalContent.Employee && this.modalContent.Employee.Photo[0]) ? baseUrl + this.modalContent.Employee.Photo[0].url : undefined;
    }
  },

  data() {
    return {
      selectedEmployee: null,
      showResultMessage: false,
      requestInfo: {dataUsage: false},
      isValidEmail: true,

      errMsg: [],

      ButtonColor: {
        backgroundColor: '#1e4295 !important',
        color: '#ffffff !important',
      },

      ButtonColorOutline: {
        borderColor: '#1e4295 !important',
        color: '#1e4295 !important'
      },

      CircleColor: {
        backgroundColor: '#1e4295 !important',
        color: '#ffffff !important'
      },

      LineColor: {
        borderRight: '1px solid #1e4295 !important',
        color: '#ffffff !important'
      }
    }
  },

  methods: {
    getBaseUrl() {
      return baseUrl;
    },

    selectEmployee(employee) {
      this.selectedEmployee = employee;
      this.showResultMessage = false;
    },

    sendRequest() {
      console.log(this.requestInfo);

      if (!this.validateForm()) return;

      this.showResultMessage = true;
      var requestData = {
        "GDPR_Accepted": this.requestInfo.dataUsage,
        "Source": "Request",
        "Company": this.companyId,
        "Company_Name": this.requestInfo.company,
        "First_Name": this.requestInfo.firstName,
        "Last_Name": this.requestInfo.lastName,
        "Email": this.requestInfo.email,
        "Phone_Number": this.requestInfo.phoneNumber,
        "Message": this.requestInfo.message
      }
      requestData.Request = {};
      if (this.selectedEmployee) {
        requestData.Request.Employee = this.selectedEmployee.Employee.id;
      }

      let selectedTimeBlock = null;
      if (this.requestInfo.timeBlock) {
        selectedTimeBlock = this.selectedEmployee.Time_Blocks.filter(f => f.id === this.requestInfo.timeBlock)[0];
        requestData.Request.Time_Block = selectedTimeBlock.id;
        requestData.Request.Date = this.requestInfo.terminDate;
      }

      console.log("Send Request Data");
      httpPost("/requests", requestData)
        .then((data) => {
          console.log("Send Request EMail")

          requestData.EmailData = {};
          requestData.EmailData.email = this.selectedEmployee.Employee.email;
          requestData.EmailData.companyName = this.companyName;

          if (selectedTimeBlock) {
            requestData.EmailData.timeBlock = selectedTimeBlock.Name;
          }

          if(this.requestInfo.terminDate) {
            let dateArray = this.requestInfo.terminDate.split("-");
            requestData.EmailData.date = dateArray[2] + '.' + dateArray[1] + '.' + dateArray[0];
          }

          httpPost("/requestEmail", requestData).then(mailResponse => {
            console.log(mailResponse);
          }).catch((error) => {
            console.log(error);
          });

          this.showResultMessage = true;
          this.requestInfo = {};
          this.requestInfo.dataUsage = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    closeModal() {
      this.showResultMessage = false;
      this.requestInfo = {};
      this.requestInfo.dataUsage = false;
    },

    validateForm() {
      this.errMsg = [];
      let isValid = true;
      if (!this.requestInfo.firstName || this.requestInfo.firstName.length == 0) {
        this.errMsg['firstName'] = 'Firstname is required!';
        isValid = false;
      }
      if (!this.requestInfo.lastName || this.requestInfo.lastName.length == 0) {
        this.errMsg['lastName'] = 'Lastname is required!';
        isValid = false;
      }
      if (!this.requestInfo.email || this.requestInfo.email.length == 0) {
        this.errMsg['email'] = 'Email is required!';
        isValid = false;
      }
      if (this.requestInfo.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.requestInfo.email))) {
        this.errMsg['email'] = 'Email not valid!';
        isValid = false;
      }
      if (!this.requestInfo.phoneNumber || this.requestInfo.phoneNumber.length == 0) {
        this.errMsg['phoneNumber'] = 'Phone number is required!';
        isValid = false;
      }
      return isValid;
    }
  }
}
</script>


<style lang="scss">
button {
  position: relative;
}

.hidden {
  display: none !important;
}

.request-us {
  padding: 0 0 20px;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .contact-form {
    h6 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      color: var(--grey-font-color);
    }

  }
}

.employee-request {
  .employee-box {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
    position: relative;
  }

  .employee-form-request {
    background-color: var(--form-box-bg-color);
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}


input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.input-box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0 !important;
  margin-top: 0 !important;

  .step {
    position: relative;
    height: 100%;

    .circle {
      background-color: var(--button-bg-default);
      color: var(--secondary-font-color) !important;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .line {
      width: 50%;
      height: calc(100% - 32px);
      min-height: calc(100% - 32px);
      border-right: 1px solid var(--button-bg-default);
    }
  }

  .input {
    width: 100%;
    padding: 5px 10px 20px;
    position: relative;

    .form-check {
      margin-bottom: 15px;
    }
  }

  .error {
    border-color: red;
  }
}

.employee-box {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: var(--white);
  box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin-bottom: 20px;
  cursor: auto;
  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .employee {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;

    .employee-info {
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }

      img {
        border-radius: 50%;
        margin-bottom: 0;
      }

      .position {
        font-size: 14px;
        color: var(--grey-font-color);
        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
      }

      .employee-name {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      }
    }

    .employee-action {
      .btn-modal {
        text-transform: uppercase;
        font-size: 12px;
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }

        &.outline {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
