import Vue from 'vue'
import VueRouter from 'vue-router'
import View from '../views/View.vue'

Vue.use(VueRouter)

const routes = [
  // TODO Burada ne yapmak gerek.
  {
    path: '/',
    name: 'Home',
    component: View,
    props:true
  },
  {
    path: '/:siteUrl',
    name: 'View',
    component: View,
    props:true
  }
  // {
  //   path: '/:companyName/meeting',
  //   name: 'Meeting',
  //   props:true,
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Meeting.vue')
  // },
  // {path: '/:notFound(.*)', },
]

const router = new VueRouter({
  mode:"history",
  routes: routes
})

export default router
