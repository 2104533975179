<template>
  <!-- Modal Imprint -->
  <div :id="configData.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-imprint" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title imprint" id="Imprint">
            {{ configData.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-html="configData.Description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalImprint',
  props: {
    configData: {type: Object, required: true},
    modalConfig: {type: Object, required: true},
  },
  data() {
    return {}
  },
}
</script>


<style lang="scss">
button {
  position: relative;
}
</style>
