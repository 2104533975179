<template>
  <div
    :class="['sidebar',configData.Navigation_Template.Style, configData.Navigation_Template.Positions, configData.Navigation_Template.Mode]">

    <div class="navbar" v-bind:class="{ collapsed: Collapse }">

      <button type="button" class="btn btn-collapse" v-on:click="Collapse = !Collapse"
              v-bind:class="{ collapsed: Collapse }"></button>

      <div class="logo" v-if="configData.Logo">
        <img
          :src="logoImageUrl"
          width="167" height="100"
          class="img-fluid"
          :alt="configData.Logo.alternativeText"
          :title="configData.Logo.caption"
        >
      </div>
      <div class="navigation">

        <!-- Views-->

        <span class="title">{{ $t('navigation.title') }}</span>

        <div class="view-buttons">
          <button type="button" id="goInside" class="btn btn-nav btn-view inside touchpointgo-inside">
            <span class="btn-view-text">{{ $t('navigation.inside') }}</span>
          </button>
          <button type="button" id="goOutside" class="btn btn-nav btn-view outside">
            <span class="btn-view-text">{{ $t('navigation.outside') }}</span>
          </button>
          <button type="button" id="fullScreen" class="btn btn-nav btn-view fullscreen">
            <span class="btn-view-text">{{ $t('navigation.fullscreen') }}</span>
          </button>
        </div>

        <!-- Touchpoints-->
        <span class="title">{{ $t('navigation.touchPoints') }}</span>
        <div class="touchpoint-buttons" id="touckpoints-buttons">
          <!--see threejs.vue-->
        </div>

        <span class="title" v-if="isMeeting">{{ $t('navigation.meetingTouchPoints') }}</span>
        <div v-if="isMeeting" class="touchpoint-buttons" id="touckpoints-buttons-meeting">
          <!--see threejs.vue-->
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import {baseUrl} from "@/http-utils";

export default {
  name: 'Navigation',

  props: {
    configData: {type: Object, required: true},
  },

  computed: {
    logoImageUrl: function () {
      return this.configData.Logo ? baseUrl + this.configData.Logo.url : undefined;
    },

    isMeeting: function () {
      return (this.$route.query && this.$route.query.meeting);
    }
  },

  data() {
    return {
      Collapse: false
    }
  },
  mounted() {
    var elem = document.getElementById("wrapper");
    document.querySelector('#fullScreen').addEventListener('click', () => {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    });
  },
}
</script>

<style lang="scss">
.sidebar {
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 99;

  &.floating {
    height: 100vh;
    width: 300px;
    overflow: hidden;
    top: initial;
    bottom: initial;
    left: 0;
    right: initial;

    &.top {
      height: 100px;
      width: 100%;
      overflow: hidden;
      top: 0;
      bottom: initial;
      left: initial;
      right: initial;
      align-items: flex-start;
      justify-content: center;

      .navbar {
        min-height: 80px;
        height: 80px;
        width: auto;
        padding: 0px 10px;
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top-right-radius: 0;
        border-bottom-right-radius: 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 20px;
        top: 0;
        bottom: initial;
        left: initial;
        right: initial;
        animation: 1.0s ease-out 0s 1 slideInFromTop;
        transition: 0.5s;

        &.collapsed {
          transition: 0.5s;
          top: -60px;
          bottom: initial;
          left: initial;
          right: initial;
        }

        .logo {
          margin: 0;
          width: auto;
          margin-right: 15px;

          img {
            width: 100px;
            height: 60px;
          }
        }

        .btn-collapse {
          right: 50%;
          left: initial;
          top: 65px;
          @include collapse-icon($icon-arrow-up, 8px, 5px);

          &.collapsed {
            @include collapse-icon($icon-arrow-down, 8px, 5px);
          }
        }

        .navigation {
          width: auto;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .view-buttons {
            border-right: 1px dashed var(--line-color);
            margin-right: 12px;
            padding-right: 7px;
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .touchpoint-buttons {
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .title {
            display: none;
          }

          .btn-nav {
            margin-bottom: 0;
            width: auto;
            position: relative;
            margin-right: 3px;

            &.btn-view {
              &::before {
                margin-right: 0px;
              }
            }

            &::before {
              margin-right: 0px;
            }

            &.active {
              .btn-view-text,
              .btn-text {
                display: none;
              }

              &::before {
                margin-right: 0;
              }
            }

            &:hover {
              .btn-view-text,
              .btn-text {
                display: initial;
                position: fixed;
                background-color: var(--secondary-bg-color);
                color: var(--secondary-font-color);
                box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
                padding: 5px 8px;
                border-radius: 10px;
                z-index: 1;
                top: 60px;
                margin-left: -13px;
                font-size: 14px;
              }

              &::before {
                margin-right: 0;
              }
            }

            .btn-view-text {
              display: none;
            }

            .btn-text {
              display: none;
            }
          }
        }

        .footer {
          width: 180px;
          font-size: 10px;
          margin: 0;
          padding: 0;
          border-top: 0;
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px dashed var(--line-color);
        }
      }
    }

    &.bottom {
      height: 100px;
      width: 100%;
      overflow: hidden;
      top: initial;
      bottom: 0;
      left: initial;
      right: initial;
      align-items: flex-end;
      justify-content: center;

      .navbar {
        min-height: 80px;
        height: 80px;
        width: auto;
        padding: 0px 10px;
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 0;
        top: initial;
        bottom: 0;
        left: initial;
        right: initial;
        animation: 1.0s ease-out 0s 1 slideInFromBottom;
        transition: 0.5s;

        &.collapsed {
          transition: 0.5s;
          top: initial;
          bottom: -60px;
          left: initial;
          right: initial;
        }

        .logo {
          margin: 0;
          width: auto;
          margin-right: 15px;

          img {
            width: 100px;
            height: 60px;
          }
        }

        .btn-collapse {
          right: 50%;
          left: initial;
          top: -10px;
          @include collapse-icon($icon-arrow-down, 8px, 5px);

          &.collapsed {
            @include collapse-icon($icon-arrow-up, 8px, 5px);
          }
        }

        .navigation {
          width: auto;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .view-buttons {
            border-right: 1px dashed var(--line-color);
            margin-right: 12px;
            padding-right: 7px;
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .touchpoint-buttons {
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .title {
            display: none;
          }

          .btn-nav {
            margin-bottom: 0;
            width: auto;
            position: relative;
            margin-right: 3px;

            &.btn-view {
              &::before {
                margin-right: 0px;
              }
            }

            &::before {
              margin-right: 0px;
            }

            &.active {
              .btn-view-text,
              .btn-text {
                display: none;
              }

              &::before {
                margin-right: 0;
              }
            }

            &:hover {
              .btn-view-text,
              .btn-text {
                display: initial;
                position: fixed;
                background-color: var(--secondary-bg-color);
                color: var(--secondary-font-color);
                box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
                padding: 5px 8px;
                border-radius: 10px;
                z-index: 1;
                bottom: 60px;
                margin-left: -13px;
                font-size: 14px;
              }

              &::before {
                margin-right: 0;
              }
            }

            .btn-view-text {
              display: none;
            }

            .btn-text {
              display: none;
            }
          }
        }

        .footer {
          width: 180px;
          font-size: 10px;
          margin: 0;
          padding: 0;
          border-top: 0;
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px dashed var(--line-color);
        }
      }
    }

    &.left {
      top: initial;
      bottom: initial;
      left: 0;
      right: initial;
      justify-content: flex-start;
    }

    &.right {
      top: initial;
      bottom: initial;
      left: initial;
      right: 0;
      justify-content: flex-end;

      .navbar {
        min-height: 250px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        animation: 1.0s ease-out 0s 1 slideInFromRight;
        transition: 0.5s;

        &.collapsed {
          transition: 0.5s;
          top: initial;
          bottom: initial;
          left: 250px;
          right: 250px;
        }

        .btn-collapse {
          right: initial;
          left: -12px;
          @include collapse-icon($icon-arrow-right, 5px, 8px);

          &.collapsed {
            @include collapse-icon($icon-arrow-left, 5px, 8px);
          }
        }
      }
    }
  }

  &.fixed {
    height: 100vh;
    width: 300px;
    overflow: hidden;
    top: initial;
    bottom: initial;
    left: 0;
    right: inherit;

    &.top {
      height: 100px;
      width: 100%;
      overflow: hidden;
      top: 0;
      bottom: initial;
      left: initial;
      right: initial;
      align-items: flex-start;
      justify-content: center;

      .navbar {
        min-height: 80px;
        height: 80px;
        width: 100%;
        padding: 0px 10px;
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0px;
        top: 0;
        bottom: initial;
        left: initial;
        right: initial;
        animation: 1.0s ease-out 0s 1 slideInFromTop;
        transition: 0.5s;

        &.collapsed {
          transition: 0.5s;
          top: -60px;
          bottom: initial;
          left: initial;
          right: initial;
        }

        .logo {
          margin: 0;
          width: auto;
          margin-right: 15px;

          img {
            width: 101px;
            height: 60px;
          }
        }

        .btn-collapse {
          right: 50%;
          left: initial;
          top: 65px;
          @include collapse-icon("../assets/icons/icon-arrow-up.svg", 8px, 5px);

          &.collapsed {
            @include collapse-icon("../assets/icons/icon-arrow-down.svg", 8px, 5px);
          }
        }

        .navigation {
          width: auto;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .view-buttons {
            border-right: 1px dashed var(--line-color);
            margin-right: 12px;
            padding-right: 7px;
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .touchpoint-buttons {
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .title {
            display: none;
          }

          .btn-nav {
            margin-bottom: 0;
            width: auto;
            position: relative;
            margin-right: 3px;

            &.btn-view {
              &::before {
                margin-right: 0px;
              }
            }

            &::before {
              margin-right: 0px;
            }

            &.active {
              .btn-view-text,
              .btn-text {
                display: none;
              }

              &::before {
                margin-right: 0;
              }
            }

            &:hover {
              .btn-view-text,
              .btn-text {
                display: initial;
                position: fixed;
                background-color: var(--secondary-bg-color);
                color: var(--secondary-font-color);
                box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
                padding: 5px 8px;
                border-radius: 10px;
                z-index: 1;
                top: 60px;
                margin-left: -13px;
                font-size: 14px;
              }

              &::before {
                margin-right: 0;
              }
            }

            .btn-view-text {
              display: none;
            }

            .btn-text {
              display: none;
            }
          }
        }

        .footer {
          width: 180px;
          font-size: 10px;
          margin: 0;
          padding: 0;
          border-top: 0;
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px dashed var(--line-color);
        }
      }
    }

    &.bottom {
      height: 100px;
      width: 100%;
      overflow: hidden;
      top: initial;
      bottom: 0;
      left: initial;
      right: initial;
      align-items: flex-end;
      justify-content: center;

      .navbar {
        min-height: 80px;
        height: 80px;
        width: 100%;
        padding: 0px 10px;
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        top: initial;
        bottom: 0;
        left: initial;
        right: initial;
        animation: 1.0s ease-out 0s 1 slideInFromBottom;
        transition: 0.5s;

        &.collapsed {
          transition: 0.5s;
          top: initial;
          bottom: -60px;
          left: initial;
          right: initial;
        }

        .logo {
          margin: 0;
          width: auto;
          margin-right: 15px;

          img {
            width: 100px;
            height: 60px;
          }
        }

        .btn-collapse {
          right: 50%;
          left: initial;
          top: -10px;
          @include collapse-icon("../assets/icons/icon-arrow-down.svg", 8px, 5px);

          &.collapsed {
            @include collapse-icon("../assets/icons/icon-arrow-up.svg", 8px, 5px);
          }
        }

        .navigation {
          width: auto;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .view-buttons {
            border-right: 1px dashed var(--line-color);
            margin-right: 12px;
            padding-right: 7px;
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .touchpoint-buttons {
            display: flex;
            flex-direction: row;
            width: auto;
          }

          .title {
            display: none;
          }

          .btn-nav {
            margin-bottom: 0;
            width: auto;
            position: relative;
            margin-right: 3px;

            &.btn-view {
              &::before {
                margin-right: 0px;
              }
            }

            &::before {
              margin-right: 0px;
            }

            &.active {
              .btn-view-text,
              .btn-text {
                display: none;
              }

              &::before {
                margin-right: 0;
              }
            }

            &:hover {
              .btn-view-text,
              .btn-text {
                display: initial;
                position: fixed;
                background-color: var(--secondary-bg-color);
                color: var(--secondary-font-color);
                box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
                padding: 5px 8px;
                border-radius: 10px;
                z-index: 1;
                bottom: 60px;
                margin-left: -13px;
                font-size: 14px;
              }

              &::before {
                margin-right: 0;
              }
            }

            .btn-view-text {
              display: none;
            }

            .btn-text {
              display: none;
            }
          }
        }

        .footer {
          width: 180px;
          font-size: 10px;
          margin: 0;
          padding: 0;
          border-top: 0;
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px dashed var(--line-color);
        }
      }
    }

    &.left {
      top: initial;
      bottom: initial;
      left: 0;
      right: inherit;
      justify-content: flex-start;

      .navbar {
        min-height: 100vh;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
      }
    }

    &.right {
      top: initial;
      bottom: initial;
      left: inherit;
      right: 0;
      justify-content: flex-end;

      .navbar {
        min-height: 100vh;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        animation: 1.0s ease-out 0s 1 slideInFromRight;
        transition: 0.5s;

        &.collapsed {
          left: 250px;
          right: -250px;
          transition: 0.5s;
        }

        .btn-collapse {
          right: inherit;
          left: -12px;
          @include collapse-icon("../assets/icons/icon-arrow-right.svg", 5px, 8px);

          &.collapsed {
            @include collapse-icon("../assets/icons/icon-arrow-left.svg", 5px, 8px);
          }
        }
      }
    }
  }

  .logo {
    width: 100%;
  }

  .navbar {
    background-color: var(--primary-bg-color);
    box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.2);
    width: 270px;
    min-height: 250px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    top: initial;
    bottom: initial;
    left: 0;
    right: initial;
    padding: 5px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    animation: 1.0s ease-out 0s 1 slideInFromLeft;
    transition: 0.5s;

    &.collapsed {
      left: -250px;
      right: 250px;
      transition: 0.5s;
    }

    .btn-collapse {
      background-color: var(--button-bg-collapse);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
      width: 24px;
      height: 24px;
      border-radius: 100%;
      padding: 0;
      margin: 0;
      position: absolute;
      right: -12px;
      top: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: var(--button-bg-collapse-hover);
      }

      @include collapse-icon($icon-arrow-left, 5px, 8px);

      &.collapsed {
        @include collapse-icon($icon-arrow-right, 5px, 8px);
      }
    }
  }

  .navigation {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .title {
      font-size: 12px;
      color: var(--grey-font-color);
      text-transform: uppercase;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .view-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .touchpoint-buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .btn-nav {
      background-color: transparent;
      border-radius: 10px;
      text-align: left;
      display: flex;
      align-items: center;
      padding: 7px 7px;
      font-size: 14px;
      color: var(--button-font-color);

      margin-bottom: 3px;
      width: 100%;
      position: relative;

      &.active {
        background-color: var(--button-bg-active);
      }

      &:hover {
        background-color: var(--button-bg-hover);
      }

      &::before {
        margin-right: 15px;
      }

      &.outside {
        @include before-nav-icon($icon-minus, 16px, 16px,);
      }

      &.inside {
        @include before-nav-icon($icon-plus, 16px, 16px);
      }

      &.fullscreen {
        @include before-nav-icon($icon-fullscreen, 14px, 14px);
      }

      &.content {
        @include before-nav-icon($icon-content, 14px, 14px);
      }

      &.video {
        @include before-nav-icon($icon-video, 18px, 12px);
      }

      &.brochures {
        @include before-nav-icon($icon-brochures, 18px, 14px);
      }

      &.request {
        @include before-nav-icon($icon-request, 20px, 16px);
      }

      &.contact {
        @include before-nav-icon($icon-contact, 16px, 12px);
      }

      &.imprint {
        @include before-nav-icon($icon-imprint, 16px, 14px);
      }

      &.join {
        @include before-nav-icon($icon-join, 18px, 16px);
      }

      &.meeting {
        @include before-nav-icon($icon-meeting, 18px, 16px);
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

</style>
