/**
 * Http Utility functions.
 */

export const baseUrl = process.env.VUE_APP_BASE_URL;

export default function httpGet(url) {
    return fetch(baseUrl + url, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Could not save data!');
        }
    });
}

export function httpPost(url, data) {
    return fetch(baseUrl + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Could not save data!');
        }
    });
}

export function httpPostForFile(url, data) {
    return fetch(baseUrl + url, {
        responseType: 'blob',
        headers: {
            'Accept': 'application/octet-stream',
        }
    }).then((response) => {
        if (response.ok) {
            return response;
        } else {
            throw new Error('Could not save data!');
        }
    });
}

