<template>
  <!-- Modal Content -->
  <div :id="modalContent.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-content" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title content" id="Content">
            {{ modalContent.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-image" v-if="modalContent.Highlight_Image">
          <img
            :src="highlightImageUrl"
            width="100%" height="180"
            class="img-fluid"
          >
        </div>
        <div class="modal-body">
          <p v-html="modalContent.Summary"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {baseUrl} from '@/http-utils';

export default {
  name: 'ModalContent',

  props: {
    modalConfig: {type: Object, required: true},
    modalContent: {type: Object, required: true},
  },

  computed: {
    highlightImageUrl: function () {
      return this.modalContent.Highlight_Image ? baseUrl + this.modalContent.Highlight_Image.url : undefined;
    }
  },

  data() {
    return {}
  },
}
</script>


<style lang="scss">
button {
  position: relative;
}
</style>
