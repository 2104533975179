import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {en, de} from './languages';

Vue.use(VueI18n);

const messages = {
  en, de
};

// if (!localStorage.getItem("lang")) {
//   let browserLang = Object.keys(messages).find((lang) => {
//     return lang == navigator.language.substring(0, 2)
//   })
//   browserLang ? localStorage.setItem("lang", browserLang) : localStorage.setItem("lang", "en")
// }

const i18n = new VueI18n({
  // locale: localStorage.getItem("lang"),
  locale: navigator.language.substring(0, 2),
  fallbackLocale: 'en',
  messages,
});

export default i18n;
