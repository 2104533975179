<template>
  <div class="wrapper" id="loading" v-if="isLoading">
    
  </div>
  <div class="wrapper" id="wrapper" v-else>
    <!--Loading View-->
    <Loading :config-data="configData" :imprint-config="imprint" :loading-process="loadingProcess"/>
    <!--Loading View-->
    <!-- Views-->
    <div class="view">

      <Navigation :config-data="configData"/>


      <ModalImprint v-if="imprint" :config-data="imprint" :modal-config="configData.Modal_Template"/>

      <ModalContent v-for="content in contents" :key="content.id" :modal-content="content"
                    :modal-config="configData.Modal_Template"/>
      <ModalVideo v-for="video in videos" :key="video.id" :modal-content="video"
                  :modal-config="configData.Modal_Template"/>
      <ModalBrochures v-for="brochure in brochures" :key="brochure.id" :modal-content="brochure"
                      :modal-config="configData.Modal_Template"/>
      <ModalRequest v-for="request in requests" :key="request.id" :modal-content="request"
                    :modal-config="configData.Modal_Template"
                    :company-id="companyId"
                    :company-name="companyName"
      />
      <ModalContact v-for="contact in contacts" :key="contact.id" :modal-content="contact"
                    :modal-config="configData.Modal_Template"
                    :company-id="companyId"
                    :company-name="companyName"
      />

      <ModalMeeting v-if="meeting" :modal-config="configData.Modal_Template" :modal-content="meetings[0]"
                    :meeting-room="meeting"/>


    </div>
    <!--Views-->

    <ThreeJS :floor-image-config="configData.FloorImage" :panorama-image-config="configData.PanoramaImage"
               :camera-and-light-config="configData.Configuration"
               :touchpoints-data="touchpointsData"
               :glb-config="configData.GBL_3D"
               :meeting-glb-config="configData.Meeting_GBL_3D"
               @increase-loading="increaseLoading"/>
  </div>
</template>


<script>
// @ is an alias to /src
import ThreeJS from '@/components/ThreeJS.vue'
import Loading from '@/components/Loading.vue'
import Navigation from '@/components/Navigation.vue'
import ModalContent from '@/components/Modals/Content.vue'
import ModalImprint from '@/components/Modals/Imprint.vue'
import ModalVideo from '@/components/Modals/Video.vue'
import ModalBrochures from '@/components/Modals/Brochures.vue'
import ModalContact from '@/components/Modals/Contact.vue'
import ModalRequest from '@/components/Modals/Request.vue'
import ModalMeeting from '@/components/Modals/Meeting.vue'
import httpGet from "@/http-utils";
import {baseUrl} from "../http-utils";

export default {
  name: 'View1',
  components: {
    ThreeJS,
    Loading,
    Navigation,
    ModalContent,
    ModalImprint,
    ModalVideo,
    ModalBrochures,
    ModalContact,
    ModalRequest,
    ModalMeeting
  },

  data() {
    return {
      isLoading: false,
      loadingProcess: 0,
      configData: {},
      companyId: null,
      companyName: null,

      // Modal Window
      contents: [],
      videos: [],
      brochures: [],
      requests: [],
      contacts: [],
      imprints: [],
      imprint: {},

      meetings: [],
      meeting: null,

      // Touchpoints Data
      touchpointsData: [] = [],
    }
  },

  props: {
    siteUrl: {type: String, required: true}
  },

  created() {
    this.loadConfigData();
  },

  methods: {
    loadConfigData() {
      if (this.siteUrl) {
        this.isLoading = true;
        var self = this;
        httpGet("/publications?Site_URL=" + this.siteUrl)
          .then((data) => {
            self.isLoading = false;
            self.configData = data[0];
            self.companyId = data[0].Company.id;
            self.companyName = data[0].Company.Company_Name;

            // Load Modal Windows and TouchpointsData
            self.loadTouchpointsData();

            if (self.imprints) {
              self.imprint = self.imprints[0];
            }

            if (self.meetings) {
              if (self.$route.query && self.$route.query.meeting) {
                let meetingRoomNo = self.$route.query.meeting;
                self.meeting = self.meetings[0].Meetings_Room[(+meetingRoomNo) - 1];
              }
            }

            self.changeFaviconAndTitle();
          })
          .catch((error) => {
            self.isLoading = false;
            console.log(error);
          });
      }
    },

    increaseLoading(process) {
      this.loadingProcess = process;
    },

    changeFaviconAndTitle() {
      var self = this;
      if(self.configData && self.configData.Meta_Tags && self.configData.Meta_Tags.Favicon) {
        // accessing the link tag
        const favicon = document.getElementById("favicon");
        favicon.href = baseUrl + self.configData.Meta_Tags.Favicon.url;

        const faviconApple = document.getElementById("faviconApple");
        faviconApple.href = baseUrl + self.configData.Meta_Tags.Favicon.url;
      }
      if(self.configData && self.configData.Meta_Tags && self.configData.Meta_Tags.Title) {
        // accessing the title tag
        const title = document.getElementById("index-title");
        title.text = self.configData.Meta_Tags.Title;
      }
    },

    loadTouchpointsData() {
      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.content').forEach(item => {
        this.contents.push(item);

        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'content',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.video').forEach(item => {
        this.videos.push(item);

        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'video',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.pdf').forEach(item => {
        this.brochures.push(item);

        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'brochures',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.request').forEach(item => {
        this.requests.push(item);

        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'request',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.contact').forEach(item => {
        this.contacts.push(item);

        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'contact',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.meeting').forEach(item => {
        this.meetings.push(item);

        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'meeting',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.imprint').forEach(item => {
        this.imprints.push(item);

        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {},
            cameraView: {},
            justModal: 1,
            buttonName: item.Button,
            contentType: 'imprint',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });

      this.configData.Touchpoints.filter(f => f.__component === 'touchpoints.join').forEach(item => {
        let xyzPos = item.Camera_Position_XYZ.split(",");
        let xyzView = item.Camera_View_XYZ.split(",");
        this.touchpointsData.push(
          {
            id: item.Touchpoints_ID,
            cameraPosition: {x: +xyzPos[0], y: +xyzPos[1], z: +xyzPos[2]},
            cameraView: {x: +xyzView[0], y: +xyzView[1], z: +xyzView[2]},
            justModal: 0,
            buttonName: item.Button,
            contentType: 'join',
            isInMeeting: item.Show === 'Meeting'
          },
        );
      });


    },
  },

}
</script>
