<template>
  <!-- Modal Contact -->
  <div :id="modalContent.Touchpoints_ID"
       class="modal fade"
       :class="modalConfig.Position"
       tabindex="-1" aria-labelledby="modal-contact" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title contact" id="Contact">
            {{ modalContent.Title }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <!--contact-us-->
          <div class="contact-us">
            <!--  if no employee selected hide the-->
            <div class="employee-info" v-if="modalContent.Employee">
              <div class="employee-img">
                <img v-if="modalContent.Employee.Photo[0]"
                     :src="employeeImageUrl"
                     width="105" height="105"
                     class="img-fluid"
                     :alt="modalContent.Employee.Photo[0].alternativeText"
                     :title="modalContent.Employee.Photo[0].caption"
                >
                <img v-else
                     src="http://go.fxw.io/vivicon.online/downloads/default-image.jpg"
                     width="105" height="105"
                     class="img-fluid"
                >
                <span><strong>{{ modalContent.Employee.Full_Name }}</strong></span>
                <span class="position">{{ modalContent.Employee.Position }}</span>
              </div>
            </div>
            <!---->
            <div class="contact-form">
              <p>{{ modalContent.Description }}</p>

              <!-- alert-->
              <div class="alert alert-success" role="alert" v-if="showResultMessage">
                {{ $t('contact.successMessage') }}
              </div>

              <form class="row g-3" :class="{ hidden: showResultMessage }" @submit.prevent="sendRequest">
                <div class="col-12">
                  <!-- <label for="inputCompany" class="form-label">Name der Firma (Optional)</label> -->
                  <input type="text" class="form-control" id="inputCompany" :placeholder="$t('contact.form.company')"
                         v-model="contactInfo.company">
                </div>

                <div class="col-6">
                  <!-- <label for="inputCompany" class="form-label">Name der Firma (Optional)</label> -->
                  <input type="text" class="form-control" id="inputFirstName"
                         :placeholder="$t('contact.form.firstName')"
                         v-model="contactInfo.firstName"
                         required
                         :class="{error: errMsg['firstName'] !== undefined}">
                </div>

                <div class="col-6">
                  <!-- <label for="inputCompany" class="form-label">Name der Firma (Optional)</label> -->
                  <input type="text" class="form-control" id="inputLastName" :placeholder="$t('contact.form.lastName')"
                         v-model="contactInfo.lastName"
                         required
                         :class="{error: errMsg['lastName'] !== undefined}">
                </div>


                <div class="col-md-12">
                  <!-- <label for="inputEmail" class="form-label">Email</label> -->
                  <input type="email" class="form-control" id="inputEmail" :placeholder="$t('contact.form.email')"
                         v-model="contactInfo.email"
                         required
                         :class="{error: errMsg['email'] !== undefined}">
                </div>

                <div class="col-md-12">
                  <!-- <label for="inputPhoneNumber" class="form-label">Email</label> -->
                  <input type="phone" class="form-control" id="inputPhoneNumber"
                         :placeholder="$t('contact.form.phoneNumber')"
                         v-model="contactInfo.phoneNumber"
                         required
                         :class="{error: errMsg['phoneNumber'] !== undefined}">
                </div>


                <div class="col-md-12">
                  <!-- <label for="textareaMessages" class="form-label">Example textarea</label> -->
                  <textarea class="form-control" id="textareaMessages" rows="3"
                            :placeholder="$t('contact.form.message')"
                            v-model="contactInfo.message"></textarea>
                </div>

                <div class="col-12">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="gridCheck" v-model="contactInfo.dataUsage"
                    required
                    :class="{error: errMsg['dataUsage'] !== undefined}">
                    <label class="form-check-label checkbox" for="gridCheck">
                      {{ $t('contact.form.dataUsage') }}
                    </label>
                  </div>
                </div>
                <div class="col-12 d-grid gap-2">
                  <button type="submit" class="btn btn-modal btn-md btn-full" :style="ButtonColor">
                    {{ $t('contact.form.button') }}
                  </button>
                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {baseUrl, httpPost} from "@/http-utils";

export default {
  name: 'ModalContact',

  props: {
    companyId: {type: String, required: true},
    companyName: {type: String, required: true},
    modalConfig: {type: Object, required: true},
    modalContent: {type: Object, required: true},
  },

  computed: {
    employeeImageUrl: function () {
      return (this.modalContent.Employee && this.modalContent.Employee.Photo[0]) ? (baseUrl + this.modalContent.Employee.Photo[0].url) : undefined;
    }
  },

  data() {
    return {
      showResultMessage: false,

      errMsg: [],

      contactInfo: {dataUsage: false},
      isValidEmail: true,

      ButtonColor: {
        backgroundColor: '#1e4295 !important',
        color: '#ffffff !important',
      },

      ButtonColorOutline: {
        borderColor: '#1e4295 !important',
        color: '#1e4295 !important'
      }
    }
  },

  methods: {
    sendRequest() {
      console.log(this.contactInfo);

      if (!this.validateForm()) return;

      this.showResultMessage = true;
      var requestData = {
        "GDPR_Accepted": this.contactInfo.dataUsage,
        "Source": "Contact",
        "Company": this.companyId,
        "Company_Name": this.contactInfo.company,
        "First_Name": this.contactInfo.firstName,
        "Last_Name": this.contactInfo.lastName,
        "Email": this.contactInfo.email,
        "Phone_Number": this.contactInfo.phoneNumber,
        "Message": this.contactInfo.message
      }
      if (this.modalContent.Employee) {
        requestData.Request = {};
        requestData.Request.Employee = this.modalContent.Employee.id;
      }

      console.log("Send Contact Data");
      httpPost("/requests", requestData)
        .then((data) => {
          console.log("Send Contact EMail")

          requestData.EmailData = {};
          requestData.EmailData.email = this.modalContent.Employee.email;
          requestData.EmailData.companyName = this.companyName;

          httpPost("/requestEmail", requestData).then(mailResponse => {
            console.log(mailResponse);
          }).catch((error) => {
            console.log(error);
          });

          this.showResultMessage = true;
          this.contactInfo = {};
          this.contactInfo.dataUsage = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    closeModal() {
      this.showResultMessage = false;
      this.contactInfo = {};
      this.contactInfo.dataUsage = false;
    },

    validateForm() {
      this.errMsg = [];
      let isValid = true;
      if (!this.contactInfo.firstName || this.contactInfo.firstName.length == 0) {
        this.errMsg['firstName'] = 'Firstname is required!';
        isValid = false;
      }
      if (!this.contactInfo.lastName || this.contactInfo.lastName.length == 0) {
        this.errMsg['lastName'] = 'Lastname is required!';
        isValid = false;
      }
      if (!this.contactInfo.email || this.contactInfo.email.length == 0) {
        this.errMsg['email'] = 'Email is required!';
        isValid = false;
      }
      if (this.contactInfo.email && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.contactInfo.email))) {
        this.errMsg['email'] = 'Email not valid!';
        isValid = false;
      }
      if (!this.contactInfo.phoneNumber || this.contactInfo.phoneNumber.length == 0) {
        this.errMsg['phoneNumber'] = 'Phone number is required!';
        isValid = false;
      }
      return isValid;
    }
  }
}
</script>


<style lang="scss">
button {
  position: relative;
}

.modal {
  &.right {
    .contact-us {
      flex-direction: row-reverse;
      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      .employee-info {
        margin-right: 0;
        margin-left: 30px;
        @include media-breakpoint-down(md) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
}

.contact-us {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0 0 20px;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  .employee-info {
    flex: 0 0 180px;
    width: 180px;
    height: 100%;
    padding: 20px;
    background-color: var(--img-bg-color);
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    cursor: auto;
    margin-right: 30px;
    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    .employee-img {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        border-radius: 50%;
        margin-bottom: 15px;
      }

      .position {
        font-size: 14px;
        color: var(--grey-font-color);
      }
    }
  }

  .contact-form {
    h6 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0;
    }

    span {
      color: var(--grey-font-color);
    }

    .btn-modal {
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        font-size: 14px;
      }
    }
  }

  .error {
    border-color: red;
  }
}
</style>
